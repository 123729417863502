.App {
    font-family: sans-serif;
    text-align: center;
    margin: 25px;
   
  }
#main{
  display: flex;
  align-items: center;  
  justify-content: left;
  margin-bottom: 4em;
  }
 
#Title{
  margin-left: 8em;
  
}
 .Para{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 25px;
}

.Container {
  margin : 3em;
  padding : 2em;    
  max-width : 750px;
  margin : 0 auto;  
}
